import type {NextPage} from 'next'
import Head from 'next/head'
import React, {useEffect} from "react";
import Layout from "../components/layouts/Layout";
import LoginSection from "../components/login/LoginSection";
import RegisterSection from "../components/login/RegisterSection";
import Container from "../components/layouts/Container";
import GuestSection from "../components/login/GuestSection";
import {deleteCookie} from "cookies-next";
import localStorageService from "../services/storage/localStorageService";
import cookieOptions from "../utils/cookieOptions";

const LoginPage: NextPage = (context: any) => {
    useEffect(() => {
        try {
            deleteCookie('sanctumToken', cookieOptions(new Date(), 0));
            localStorageService.deleteItem('lastOrder');
        }
        catch(e) {
            console.error('-- failed to clear cookies in login page', e);
        }
    }, [])

    return (
        <>
            <Head>
                <title>Perfecthair</title>
                <meta name="description" content="Perfecthair"/>
            </Head>

            <Layout context={context}>
                <Container>
                    <div className="row mt-5 mb-5">
                        <LoginSection/>
                        <RegisterSection/>
                        <GuestSection/>
                    </div>
                </Container>

            </Layout>
        </>
    )
}

export async function getServerSideProps() {
    return {
        props: {}
    };
}

export default LoginPage
