import React from "react";
import {useRouter} from "next/router";
import i18next from "i18next";
import Link from "../link/Link";

export default function GuestSection() {
    const router = useRouter();
    const isCheckout: any = router.query.checkout;

    if (!parseInt(isCheckout)) return <></>

    return (
        <>
            <div className={`col-12 ${isCheckout ? 'col-xl-4' : 'col-xl-6'}`}>
                <div className="h-100 bg-light border-light no-hover rounded-3 p-5">
                    <p className="fw-bold mb-4">{i18next.t('general.guestOrder').toString()}</p>
                    <p>{i18next.t('general.continueAsGuest').toString()}</p>
                    <div className="mt-5">
                        <Link href={`/${i18next.language}/register?guest=1&checkout=1`}
                              className="btn btn-alt w-100">
                            {i18next.t('general.continue').toString()}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}