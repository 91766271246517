import React from "react";
import {useRouter} from "next/router";
import i18next from "i18next";
import Link from "next/link";

export default function RegisterSection() {
    const router = useRouter();
    const isCheckout: any = router.query.checkout ?? false;

    return (
        <>
            <div className={`col-12 mb-4 mb-xl-0 mt-4 mt-xl-0 ${parseInt(isCheckout) ? 'col-xl-4' : 'col-xl-6'}`}>
                <div className="h-100 bg-highlight rounded-3 p-5 border-light">
                    <p className="fw-bold mb-4">{i18next.t('general.register').toString()}</p>
                    <ul className="list-unstyled mb-5">
                        <li className="mb-3">
                            <div className="row">
                                <div className="col-1">
                                    <i className="fa-solid fa-circle-check text-green"></i>
                                </div>
                                <div className="col-11">
                                    <span>{i18next.t('advantages.quickBuy').toString()}</span>
                                </div>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="row">
                                <div className="col-1">
                                    <i className="fa-solid fa-circle-check text-green"></i>
                                </div>
                                <div className="col-11">
                                    <span>{i18next.t('advantages.discount').toString()}</span>
                                </div>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="row">
                                <div className="col-1">
                                    <i className="fa-solid fa-circle-check text-green"></i>
                                </div>
                                <div className="col-11">
                                    <span>{i18next.t('advantages.order').toString()}</span>
                                </div>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="row">
                                <div className="col-1">
                                    <i className="fa-solid fa-circle-check text-green"></i>
                                </div>
                                <div className="col-11">
                                    <span>{i18next.t('advantages.crossDevice').toString()}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="mb-3">
                        <Link href={{pathname: `/${i18next.language}/register`, query: {checkout: isCheckout}}}
                              className="btn btn-alt w-100">
                            {i18next.t('general.register').toString()}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}